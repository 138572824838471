import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import ShakespeareImage from '../../assets/images/publication-banners/utah-shakespeare-festival.jpg';

export const query = graphql`
  query ShakespeareQuery {
    allShakespeareCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function UtahShakespeare({ data }) {
  const shakespeareNodes = data.allShakespeareCsv.nodes;
  // console.log(shakespeareNodes);

  const imageRef = '/season-images/shakespeare/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Utah Shakespeare Festival - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Utah Shakespeare Festival’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Utah Shakespeare Festival</h1>
            </header>
            <span className="image main">
              <img src={ShakespeareImage} alt="" />
            </span>
            <p className="box">
              The Shakespeare Festival brings together a regional, educated
              audience and performers from around the world. This playbill is a
              true keepsake—ensuring that your ad will live on beyond the stage.
            </p>
            <ul className="actions">
              <li>
                <a href="https://bard.org/" className="button special">
                  <i className="icon fa-globe"> </i>Utah Shakespeare Festival
                  Website
                </a>
              </li>
            </ul>
            <h2>
              Utah Shakespeare Festival <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <p>
              The Utah Shakespeare Festival is a Tony Award-winning,
              professional theatre located on the campus of Southern Utah
              University that presents Shakespeare and plays from other
              playwrights every June through October in three theatres at the
              Beverley Taylor Sorenson Center for the Arts.
            </p>
            <p>
              <strong>THE ENGLESTAD SHAKESPEARE THEATRE</strong> is an outdoor
              replica of Shakespeare’s Globe Theatre. <br />
              <br />
              <strong>THE RANDALL L. JONES THEATRE</strong> is an indoor modern
              theatre.
              <br />
              <br />
              <strong>THE EILEEN AND ALLEN ANES STUDIO THEATRE</strong> is an
              indoor modern black box theatre.
            </p>
            <h5>Advertising Information</h5>
            <section className="box">
              <table>
                <tbody>
                  <tr>
                    <td>File Ready Deadline: May 23, 2024</td>
                  </tr>
                  <tr>
                    <td>Circulation: 80,000</td>
                  </tr>
                </tbody>
              </table>
            </section>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {shakespeareNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image}
                  />
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  {node.Quantity}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
